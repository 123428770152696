var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{attrs:{"accept-charset":"UTF-8","role":"form"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changePassword.apply(null, arguments)}}},[(!_vm.inputsVisible)?_c('b-field',{attrs:{"message":_vm.success ? 'Password changed successfully' : '',"type":_vm.success ? 'is-success' : ''}},[_c('button',{staticClass:"button is-primary",attrs:{"type":"button"},on:{"click":_vm.showPasswordInputs}},[_c('b-icon',{attrs:{"icon":"key","size":"is-small"}}),_c('span',[_vm._v("Change Password")])],1)]):_vm._e(),(_vm.inputsVisible)?_c('div',[_c('h3',{staticClass:"title is-4"},[_vm._v(" Change Password ")]),_c('b-field',{attrs:{"type":{ 'is-danger': _vm.$v.password.$error },"message":{ 'Must be at least 8 characters': !_vm.$v.password.minLength },"label":"Current Password","horizontal":""}},[_c('b-input',{attrs:{"type":"password","password-reveal":""},on:{"input":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('b-field',{attrs:{"type":{ 'is-danger': _vm.$v.newPassword.$error },"message":[
        { 'Must be at least 8 characters': !_vm.$v.newPassword.minLength },
        { 'Password must contain a number': !_vm.$v.newPassword.containsNumber },
        { 'Password must contain a lowercase letter': !_vm.$v.newPassword.containsLowercase },
        { 'Password must contain an uppercase letter': !_vm.$v.newPassword.containsUppercase }
      ],"label":"New Password","horizontal":""}},[_c('b-input',{attrs:{"type":"password","password-reveal":""},on:{"input":function($event){return _vm.$v.newPassword.$touch()}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c('b-field',{attrs:{"type":{ 'is-danger': _vm.$v.newPasswordConfirm.$error },"message":[
        { 'Password must be at least 8 characters': !_vm.$v.newPasswordConfirm.minLength },
        { 'Password confirmation must match': !_vm.$v.newPasswordConfirm.sameAs }
      ],"label":"Confirm New Password","horizontal":""}},[_c('b-input',{attrs:{"type":"password","password-reveal":""},on:{"input":function($event){return _vm.$v.newPasswordConfirm.$touch()}},model:{value:(_vm.newPasswordConfirm),callback:function ($$v) {_vm.newPasswordConfirm=$$v},expression:"newPasswordConfirm"}})],1),(_vm.errorMessage)?_c('b-message',{attrs:{"type":"is-danger"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('b-field',{attrs:{"grouped":"","position":"is-centered"}},[_c('p',{staticClass:"control"},[_c('button',{staticClass:"button is-primary",class:{ 'is-loading': _vm.loading },attrs:{"disabled":_vm.$v.$invalid || _vm.loading,"type":"submit"}},[_vm._v(" Save New Password ")])]),_c('p',{staticClass:"control"},[_c('button',{staticClass:"button is-white",attrs:{"type":"reset"},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")])])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }