<template>
  <section class="property-details">
    <image-gallery :images="activeProperty.images" />
    <div
      class="content long-description"
      v-html="activeProperty.long_description"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import ImageGallery from '@/components/ImageGallery'

export default {
  name: 'PropertyDetails',

  components: { ImageGallery },

  computed: mapGetters(['activeProperty'])
}
</script>
