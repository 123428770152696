<template>
  <article class="media">
    <figure
      v-if="thumbnail"
      class="media-left"
    >
      <router-link
        :to="link"
        class="image is-64x64"
      >
        <img :src="thumbnail">
      </router-link>
    </figure>

    <div class="media-content">
      <div class="content">
        <router-link
          :to="link"
          class="view-link button is-info is-pulled-right"
          tag="button"
        >
          View
        </router-link>
        <h4 class="title">
          {{ name }}
        </h4>
        <p>
          <property-stats
            :bathrooms="bathrooms"
            :bedrooms="bedrooms"
            :max-occupancy="maxOccupancy"
            :occupancy="occupancy"
            :sq-feet="sqFeet"
            :star-rating="starRating"
          />
        </p>
      </div>
    </div>
  </article>
</template>

<script>
import imgUrl from '@lmpm/helpers/browser/img-url'
import PropertyStats from '@/components/PropertyStats'

export default {
  name: 'PropertyListItem',

  components: { PropertyStats },

  props: {
    bathrooms: {
      type: Number,
      default: 0
    },
    bedrooms: {
      type: Number,
      default: 0
    },
    id: {
      type: String,
      default: ''
    },
    images: {
      type: Array,
      default () { return [] }
    },
    maxOccupancy: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: ''
    },
    occupancy: {
      type: Number,
      default: 0
    },
    sqFeet: {
      type: Number,
      default: 0
    },
    starRating: {
      type: Number,
      default: 0
    }
  },

  computed: {
    thumbnail () {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!this.images?.length) return false
      const image = this.images[0]
      return imgUrl({
        companyId: image.company_id,
        path: image.path,
        fileName: image.file_name,
        queryParams: {
          w: 128,
          h: 128
        }
      })
    },
    link () { return `/properties/${this.id}/future-bookings` }
  }
}
</script>

<style lang="sass" scoped>
.content
  .title
    margin-bottom: .9rem

  .view-link
    margin-left: 1rem
</style>
