<template>
  <header>
    <div class="level">
      <div class="level-left">
        <div class="level-item property-title">
          <div>
            <h1 class="title">
              {{ property.friendly_name }}
            </h1>
            <p class="title is-6">
              <property-stats
                :bathrooms="property.bathrooms"
                :bedrooms="property.bedrooms"
                :max-occupancy="property.max_occupancy"
                :occupancy="property.occupancy"
                :sq-feet="property.sq_feet"
                :star-rating="property.star_rating"
              />
            </p>
          </div>
        </div>
      </div>

      <div class="level-right">
        <div class="level-item">
          <div>
            <a
              :href="publicUrl"
              class="button is-small has-icon"
              target="_blank"
            >
              <span>View On Website</span>
              <b-icon
                icon="open-in-new"
                size="is-small"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import PropertyStats from '@/components/PropertyStats'

export default {
  name: 'PropertyViewHeader',

  components: { PropertyStats },

  props: {
    property: {
      type: Object,
      default () { return {} } 
    } 
  },

  computed: {
    publicUrl () { return `https://${this.activeCompany.domain}/redirect/vacation-rentals/${this.property.slug}` },
    ...mapGetters(['activeCompany'])
  }
}
</script>

<style lang="sass" scoped>
.property-title
  justify-content: flex-start
</style>
