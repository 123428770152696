<template>
  <article class="media">
    <div class="media-content">
      <div class="content">
        <p>
          <strong v-if="reference">{{ reference }}</strong>
          <strong v-if="!reference">{{ vendor.name }}</strong>
          <span v-if="reference">
            <br>
            {{ vendor.name }}
          </span>
          <br>
          <span>{{ formattedDate }}</span>
        </p>
      </div>
    </div>
    <div class="media-right">
      <div class="charge-amount field is-grouped">
        <p class="control is-static">
          {{ formattedAmount }}
        </p>
        <p class="control">
          <router-link
            :to="link"
            class="view-link button is-info"
            tag="button"
          >
            View
          </router-link>
        </p>
      </div>
    </div>
  </article>
</template>

<script>
import format from 'date-fns/format'
import parse from 'date-fns/parse'

export default {
  name: 'BillListItem',

  props: {
    vendor: {
      type: Object,
      default () { return {} }
    },
    totalAmount: {
      type: Number,
      default: 0
    },
    currency: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    propertyId: {
      type: String,
      default: ''
    },
    reference: {
      type: String,
      default: ''
    }
  },

  computed: {
    formattedDate () { return format(parse(this.date), 'MMM D') },
    link () { return `/properties/${this.propertyId}/charges/${this.id}` },
    formattedAmount () {
      return this.$store.getters.formattedIntCurrencyAmount(this.totalAmount)
    }
  }
}
</script>
