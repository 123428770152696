import currencyCodeToSymbol from '@lmpm/helpers/browser/currency-code-to-symbol'
import Money from 'js-money'

export const hasCompany = (state, getters) => {
  return Boolean(getters.activeCompany)
}

export const companyRouteInvalid = (state, getters) => {
  return Boolean(state.route.meta.needsCompany && !getters.hasCompany)
}

export const protectedRouteInvalid = (state, getters) => {
  if (state.loggingOut) return false
  return Boolean(state.route.meta.needsAuth && !getters.hasCompany)
}

export const showRouteNav = state => !state.route.meta.hideNav

export const moneyToString = state => ({
  amount,
  currency,
  rounder
}) => {
  if (!rounder) rounder = Math.round
  return String(Money.fromDecimal(amount, currency, rounder))
}

export const moneyIntToString = state => ({
  amount,
  currency,
  rounder
}) => {
  if (!rounder) rounder = Math.round
  return String(Money.fromInteger(amount, currency, rounder))
}

export const formattedCurrencyAmount = (state, getters) => (amount) => {
  const currency = getters.activeCompany.currency.iso
  return `${currencyCodeToSymbol(currency)}${getters.moneyToString({
 amount,
currency
})}`
}

export const formattedIntCurrencyAmount = (state, getters) => (amount) => {
  const currency = getters.activeCompany.currency.iso
  return `${currencyCodeToSymbol(currency)}${getters.moneyIntToString({
 amount,
currency
})}`
}
