<template>
  <section class="property-booking-list">
    <b-field grouped>
      <b-field>
        <b-datepicker
          v-model="dateRange"
          placeholder="Start - End"
          range
        />
      </b-field>
      <b-field>
        <b-button
          type="is-light"
          @click="clearDatepicker"
        >
          Clear Date Range
        </b-button>
      </b-field>
    </b-field>
    <hr>
    <loading-spinner :loading="loading" />
    <b-message
      v-if="errorMessage"
      type="is-danger"
    >
      {{ errorMessage }}
    </b-message>

    <div
      v-if="!loading"
      class="list-items"
    >
      <property-item-list-item
        v-for="item of propertyItems"
        :id="item.id"
        :key="item.id"
        :adults="item.adults"
        :booking-type="item.booking_type"
        :children="item.children"
        :end-date="item.end_date"
        :guest="item.guest"
        :housekeeping-note="item.housekeeping_note"
        :property-id="item.property_id"
        :start-date="item.start_date"
        :status="item.status"
        :total="Number(item.item_total_on_owner_statement)"
        :trip-id="item.trip_id"
        :user-id="item.user_id"
      />
      <no-results v-if="!loading && !propertyItems.length && !errorMessage">
        No bookings to display.
      </no-results>
      <div v-else>
        <div v-if="propertyItems.length && !endOfList">
          <b-button
            v-if="loadingButton"
            expanded
            type="is-light"
            loading
          />
          <b-button
            v-else
            expanded
            type="is-light"
            @click="loadMore"
          >
            More...
          </b-button>
        </div>
        <div v-else>
          <b-button
            expanded
            type="is-light"
            disabled
          >
            No more bookings to display
          </b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import format from 'date-fns/format'
import Raven from 'raven-js'
import LoadingSpinner from 'vue-spinner/src/MoonLoader'
import { mapGetters } from 'vuex'
import NoResults from '@/components/NoResults'
import PropertyItemListItem from '@/components/PropertyItemListItem'
import { getErrorMessage } from '@/services/helpers'

export default {
  name: 'PropertyItemList',

  components: {
    LoadingSpinner,
    PropertyItemListItem,
    NoResults
  },

  props: {
    propertyId: {
      type: String,
      default: ''
    },
    future: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      errorMessage: null,
      loading: false,
      today: new Date(),
      loadingButton: false,
      futureChanged: false,
      endOfList: false
    }
  },

  computed: {
    propertyItems () {
      const {
        propertyId,
        future
      } = this
      return this.itemsForProperty({
        propertyId,
        future
      }).slice()
    },
    dateRange: {
      get () {
        const {
          propertyId,
          future
        } = this
        return this.dateRangeForProperty({
          propertyId,
          future
        })
      },
      set (value) {
        const {
          propertyId,
          future
        } = this
        this.$store.dispatch('setDateRange', {
          propertyId,
          future,
          dateRange: value
        })
      }
    },
    ...mapGetters([
      'itemsForProperty',
      'endOfPropertyItems',
      'dateRangeForProperty'
    ])
  },

  watch: {
    async future () {
      this.futureChanged = true
      this.$store.dispatch('updateFuture', { future: this.future })
      if (this.propertyItems.length === 0) {
        this.loading = true
        await this.getPropertyItems()
      }
    },
    async dateRange () {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (this.validateDates() && !this.futureChanged) {
        const {
          propertyId,
          future
        } = this
        this.$store.dispatch('resetPropertyItems', {
          propertyId,
          future
        })
        this.loading = true
        await this.getPropertyItems()
      }
      this.futureChanged = false
    }
  },

  async created () {
    if (this.propertyItems.length === 0) {
      this.loading = true
      await this.getPropertyItems()
    } else {
      const {
        propertyId,
        future
      } = this
      this.endOfList = this.endOfPropertyItems({
        propertyId,
        future
      })
    }
  },

  methods: {
    async getPropertyItems () {
      this.errorMessage = null
      const {
        propertyId,
        future,
        dateRange,
        today
      } = this
      try {
        let start, end
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (this.validateDates()) {
          start = format(dateRange[0], 'YYYY-MM-DD')
          end = format(dateRange[1], 'YYYY-MM-DD')
        } else {
          start = format(today, 'YYYY-MM-DD')
        }
        await this.$store.dispatch('getPropertyItems', {
          propertyId,
          start,
          end,
          future
        })
      } catch (e) {
        Raven.captureException(e)
        this.errorMessage = getErrorMessage(e)
      }
      this.endOfList = this.endOfPropertyItems({
        propertyId,
        future
      })
      this.loading = false
    },
    async loadMore () {
      this.loadingButton = true
      await this.getPropertyItems()
      this.loadingButton = false
    },
    async clearDatepicker () {
      this.dateRange = []
      this.loading = true
      const {
        propertyId,
        future
      } = this
      this.$store.dispatch('resetPropertyItems', {
        propertyId,
        future
      })
      await this.getPropertyItems()
    },
    validateDates () {
      const { dateRange } = this
      if (!Boolean(dateRange)) return false
      if (format(dateRange[0], 'YYYY-MM-DD') === 'Invalid Date' || format(dateRange[1], 'YYYY-MM-DD') === 'Invalid Date') return false
      if (dateRange[1] - dateRange[0] < 0) return false
      return true
    }
  }
}
</script>
