<template>
  <form
    accept-charset="UTF-8"
    role="form"
    @submit.stop.prevent="changePassword"
  >
    <b-field
      v-if="!inputsVisible"
      :message="success ? 'Password changed successfully' : ''"
      :type="success ? 'is-success' : ''"
    >
      <button
        class="button is-primary"
        type="button"
        @click="showPasswordInputs"
      >
        <b-icon
          icon="key"
          size="is-small"
        />
        <span>Change Password</span>
      </button>
    </b-field>

    <div v-if="inputsVisible">
      <h3 class="title is-4">
        Change Password
      </h3>

      <b-field
        :type="{ 'is-danger': $v.password.$error }"
        :message="{ 'Must be at least 8 characters': !$v.password.minLength }"
        label="Current Password"
        horizontal
      >
        <b-input
          v-model="password"
          type="password"
          password-reveal
          @input="$v.password.$touch()"
        />
      </b-field>

      <b-field
        :type="{ 'is-danger': $v.newPassword.$error }"
        :message="[
          { 'Must be at least 8 characters': !$v.newPassword.minLength },
          { 'Password must contain a number': !$v.newPassword.containsNumber },
          { 'Password must contain a lowercase letter': !$v.newPassword.containsLowercase },
          { 'Password must contain an uppercase letter': !$v.newPassword.containsUppercase }
        ]"
        label="New Password"
        horizontal
      >
        <b-input
          v-model="newPassword"
          type="password"
          password-reveal
          @input="$v.newPassword.$touch()"
        />
      </b-field>

      <b-field
        :type="{ 'is-danger': $v.newPasswordConfirm.$error }"
        :message="[
          { 'Password must be at least 8 characters': !$v.newPasswordConfirm.minLength },
          { 'Password confirmation must match': !$v.newPasswordConfirm.sameAs }
        ]"
        label="Confirm New Password"
        horizontal
      >
        <b-input
          v-model="newPasswordConfirm"
          type="password"
          password-reveal
          @input="$v.newPasswordConfirm.$touch()"
        />
      </b-field>

      <b-message
        v-if="errorMessage"
        type="is-danger"
      >
        {{ errorMessage }}
      </b-message>

      <b-field
        grouped
        position="is-centered"
      >
        <p class="control">
          <button
            :class="{ 'is-loading': loading }"
            :disabled="$v.$invalid || loading"
            class="button is-primary"
            type="submit"
          >
            Save New Password
          </button>
        </p>

        <p class="control">
          <button
            class="button is-white"
            type="reset"
            @click="cancel"
          >
            Cancel
          </button>
        </p>
      </b-field>
    </div>
  </form>
</template>

<script>
import Raven from 'raven-js'
import { validationMixin } from 'vuelidate'
import {
  required, minLength, sameAs 
} from 'vuelidate/lib/validators'
import { getErrorMessage } from '@/services/helpers'
import containsLowercase from '@/validators/contains-lowercase'
import containsNumber from '@/validators/contains-number'
import containsUppercase from '@/validators/contains-uppercase'

export default {
  name: 'ChangePassword',

  mixins: [validationMixin],

  data () {
    return {
      inputsVisible: false,
      errorMessage: null,
      loading: false,
      success: null,
      password: '',
      newPassword: '',
      newPasswordConfirm: ''
    }
  },

  validations: {
    password: {
      required,
      minLength: minLength(8) 
    },
    newPassword: {
      required,
      minLength: minLength(8),
      containsLowercase,
      containsUppercase,
      containsNumber 
    },
    newPasswordConfirm: {
      required,
      minLength: minLength(8),
      sameAs: sameAs('newPassword') 
    }
  },

  methods: {
    showPasswordInputs () {
      this.inputsVisible = true
      this.success = null
    },

    async changePassword () {
      this.errorMessage = null
      this.success = null
      this.loading = true
      try {
        await this.$store.dispatch('changePassword', {
          oldPassword: this.password,
          newPassword: this.newPassword
        })
        this.loading = false
        this.success = true
        this.inputsVisible = false
        this.password = ''
        this.newPassword = ''
        this.newPasswordConfirm = ''
      } catch (e) {
        Raven.captureException(e)
        this.errorMessage = getErrorMessage(e)
        this.loading = false
      }
    },

    cancel () {
      this.errorMessage = null
      this.success = null
      this.inputsVisible = false
      this.password = ''
      this.newPassword = ''
      this.newPasswordConfirm = ''
    }
  }
}
</script>
