<template>
  <div>
    <article class="media list-item">
      <div class="media-content">
        <div class="content">
          <strong v-if="showGuestName"> {{ guest.first_name }} {{ guest.last_name }}</strong>
          <strong v-else>{{ propertyItemBookingTypeNameLabel({ bookingType }) }} Booking</strong>
          <br>
          <span>{{ checkIn }}</span> - <span>{{ checkOut }}, {{ year }}</span>
        </div>
      </div>

      <div class="media-right">
        <div class="field is-grouped">
          <p
            v-if="showBookingValues"
            class="control"
          >
            {{ formattedTotal }}
          </p>
          <p class="control">
            <span
              v-if="bookingType"
              :class="propertyItemLabelCssClass({ bookingType })"
              class="tag"
            >{{ propertyItemBookingTypeLabel({ bookingType }) }}</span>
          </p>
          <p
            v-if="showBookingInformation"
            class="control"
          >
            <button
              class="button is-small is-info has-icon"
              @click="toggleBookingInfoVisible"
            >
              <b-icon
                icon="information-outline"
                size="is-small"
              />
            </button>
          </p>
          <p
            v-if="propertyItemIsCancellable({ bookingType, startDate })"
            class="control"
          >
            <button
              :class="{ 'is-loading': loading }"
              class="button is-danger is-small has-icon"
              @click="confirmCancelItem"
            >
              <b-icon
                icon="close-circle"
                size="is-small"
              />
            </button>
          </p>
        </div>
      </div>
    </article>
    <article
      v-if="bookingInfoVisible"
      class="message is-info booking-information"
    >
      <div class="message-body">
        <b-icon
          icon="email"
          size="is-small"
        /> {{ guest.email }}<br>
        <span v-if="getPhoneNumber(guest.phone_numbers, guest.primary_phone_number)">
          <b-icon
            icon="phone"
            size="is-small"
          /> {{ getPhoneNumber(guest.phone_numbers, guest.primary_phone_number) }}<br>
        </span>
        <b-icon
          icon="account-multiple"
          size="is-small"
        /> {{ adults }} adult<span v-if="adults > 1">s</span>
        <span v-if="children > 0">, {{ children }} child<span v-if="children > 1">ren</span></span><br>
        <span v-if="housekeepingNote">
          <b-icon
            icon="bed-empty"
            size="is-small"
          /> {{ housekeepingNote }}<br>
        </span>
      </div>
    </article>
  </div>
</template>

<script>
import addDays from 'date-fns/add_days'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import Raven from 'raven-js'
import { mapGetters } from 'vuex'
import { getErrorMessage } from '@/services/helpers'

export default {
  name: 'PropertyItemListItem',

  props: {
    adults: {
      type: Number,
      default: 0
    },
    bookingType: {
      type: String,
      default: ''
    },
    children: {
      type: Number,
      default: 0
    },
    endDate: {
      type: String,
      default: ''
    },
    guest: {
      type: Object,
      default () { return {} }
    },
    housekeepingNote: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    propertyId: {
      type: String,
      default: ''
    },
    startDate: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      default: 0
    },
    tripId: {
      type: String,
      default: ''
    },
    userId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      bookingInfoVisible: false,
      loading: false
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    formattedTotal () { if (this.total > 0) return this.$store.getters.formattedCurrencyAmount(this.total) },
    year () { return format(parse(this.endDate), 'YYYY') },
    checkIn () { return format(parse(this.startDate), 'MMM Do') },
    checkOut () { return format(addDays(parse(this.endDate), 1), 'MMM Do') },
    showBookingValues () { return Boolean(this.activeCompany.show_booking_values) },
    showBookingInformation () { return Boolean(this.activeCompany.show_booking_information) },
    showGuestName () { return Boolean(this.activeCompany.show_guest_name) },
    ...mapGetters([
      'activeCompany',
      'propertyItemBookingTypeLabel',
      'propertyItemLabelCssClass',
      'propertyItemIsCancellable',
      'propertyItemBookingTypeNameLabel'
    ])
  },

  methods: {
    confirmCancelItem () {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to cancel this booking? This action cannot be undone.',
        confirmText: 'Cancel Booking',
        cancelText: 'Don\'t Cancel Booking',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: this.cancelItem
      })
    },
    async cancelItem () {
      this.loading = true
      const propertyId = this.propertyId
      const tripId = this.tripId
      const id = this.id
      try {
        await this.$store.dispatch('cancelPropertyItem', {
          propertyId,
          tripId,
          id
        })
      } catch (e) {
        Raven.captureException(e)
        this.$buefy.toast.open({
          message: `Error cancelling booking - ${getErrorMessage(e)}`,
          type: 'is-danger'
        })
      }
      this.loading = false
    },
    toggleBookingInfoVisible () {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      this.bookingInfoVisible = !this.bookingInfoVisible
    },
    getPhoneNumber (phoneNumbers, primaryId) {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!phoneNumbers.length) return
      const phoneNumber = phoneNumbers.find(({ id }) => id === primaryId)
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!phoneNumber) return
      return phoneNumber.phone_number
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../assets/styles/variables.sass"
.item-total
  margin-right: 1rem
.list-item
  margin-bottom: 0.5rem
.booking-information
  margin-top: 0.5rem
  margin-bottom: 0.75rem
.owner-guest-bg
  background: $owner-guest-green !important
</style>
