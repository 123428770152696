<template>
  <div id="app">
    <transition
      name="fade"
      mode="out-in"
      appear
    >
      <main-nav />
    </transition>
    <transition
      name="fade"
      mode="out-in"
      appear
    >
      <div
        v-if="protectedRouteInvalid"
        class="no-company-warning notification is-warning has-text-centered"
      >
        <b-icon icon="alert-circle-outline" />
        Your account isn't linked with any companies. Contact your admin for a new invitation link.
      </div>
    </transition>
    <transition
      name="fade"
      mode="out-in"
      appear
    >
      <router-view v-if="!companyRouteInvalid" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MainNav from './components/MainNav.vue'

export default {
  name: 'App',

  components: { MainNav },

  computed: mapGetters([
    'hasCompany',
    'protectedRouteInvalid',
    'companyRouteInvalid',
    'showRouteNav'
  ])
}
</script>

<style src="./assets/styles/index.sass" lang="sass"></style>
