<template>
  <section
    :style="bgImageStyle"
    class="hero is-dark is-fullheight is-bold"
  >
    <div class="hero-body">
      <a
        v-if="$is_liverez"
        href="https://liverez.com/"
        target="_blank"
      >
        <img
          src="~@/assets/img/liverez-logo-light.svg"
          alt="LizeRez Logo"
          class="logo logo-position"
        >
      </a>
      <a
        v-else
        href="https://lmpm.com/"
        target="_blank"
      >
        <img
          src="~@/assets/img/logo-light.svg"
          alt="LMPM Logo"
          class="logo logo-position"
        >
      </a>
      <div class="container">
        <div class="columns">
          <div class="column is-4 is-offset-4">
            <div style="position: relative;">
              <div class="is-size-1-desktop is-size-3-touch">
                Owner Portal
              </div>
            </div>
            <b-message
              v-if="signedOut"
              type="is-success"
            >
              Successfully signed out.
            </b-message>
            <b-message
              v-if="expired"
              type="is-warning"
            >
              Session expired, please login again.
            </b-message>
            <b-message
              v-if="errorMessage"
              type="is-danger"
            >
              {{ errorMessage }}
            </b-message>
            <b-message
              v-if="!localStorageAvailable"
              type="is-danger"
            >
              <strong>Browser Unsupported</strong>
              <br><br>
              <p>It looks like your browser doesn't support <code>localStorage</code> which is required by this app.</p>
              <br>
              <p><strong>If you're using Safari, make sure Private Browsing is disabled</strong>. Otherwise, please try using a more modern web browser.</p>
            </b-message>

            <form
              v-if="localStorageAvailable"
              accept-charset="UTF-8"
              role="form"
              @submit.stop.prevent="userPoolLogin"
            >
              <b-field
                :type="{ 'is-danger': $v.email.$error }"
                :message="{ 'Must be a valid email address': !$v.email.email }"
              >
                <b-input
                  v-model="email"
                  icon="email"
                  placeholder="Email"
                  type="email"
                  autocomplete="email"
                  @input="$v.email.$touch()"
                />
              </b-field>

              <b-field
                :type="{ 'is-danger': $v.password.$error }"
                :message="{ 'Password must be at least 8 characters': !$v.password.minLength }"
              >
                <b-input
                  v-model="password"
                  icon="lock"
                  placeholder="Password"
                  type="password"
                  autocomplete="current-password"
                  @input="$v.password.$touch()"
                />
              </b-field>

              <b-field grouped>
                <p class="control is-expanded">
                  <router-link
                    :to="forgotPasswordLink"
                    class="button is-dark is-small"
                  >
                    Forgot Password?
                  </router-link>
                </p>

                <p class="control">
                  <button
                    :class="{ 'is-loading': loading }"
                    :disabled="$v.$invalid || loading"
                    class="button is-info"
                    type="submit"
                  >
                    Login
                  </button>
                </p>
              </b-field>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import imgUrl from '@lmpm/helpers/browser/img-url'
import { sample } from 'lodash'
import Raven from 'raven-js'
import storageAvailable from 'storage-available'
import { validationMixin } from 'vuelidate'
import {
  required, minLength, email
} from 'vuelidate/lib/validators'
import Auth from '@/services/auth'
import { getErrorMessage } from '@/services/helpers'
import {
  loginBgImages, expectedLoginErrorCodes
} from '@/store/constants'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Login',

  mixins: [validationMixin],

  beforeRouteLeave (to, from, next) {
    setTimeout(() => {
      this.loading = false
      this.password = ''
    }, 1000)
    next()
  },

  props: {
    companyId: {
      type: String,
      default: ''
    },
    expired: {
      type: Boolean,
      default: false
    },
    prepopEmail: {
      type: String,
      default: ''
    },
    redirect: {
      type: String,
      default: ''
    },
    signedOut: {
      type: Boolean,
      default: false
    },
    userId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      email: this.prepopEmail || '',
      errorMessage: null,
      loading: false,
      password: '',
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      redirectLink: this.redirect || '/properties'
    }
  },

  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(8)
    }
  },

  computed: {
    forgotPasswordLink () {
      return `/login/forgot?email=${this.email}`
    },

    randomBgImg () {
      return sample(loginBgImages)
    },

    bgImageStyle () {
      const bgImgUrl = imgUrl({
        companyId: 'lmpm',
        path: 'owners-app-assets/login-bg-images',
        fileName: this.randomBgImg,
        queryParams: {
          w: 2560,
          q: 20
        }
      })
      return `background-image: url(${bgImgUrl})`
    },

    localStorageAvailable () {
      return storageAvailable('localStorage')
    }
  },

  methods: {
    async userPoolLogin () {
      this.errorMessage = null
      this.loading = true
      try {
        await Auth.userPoolLogin({
          email: this.email.toLowerCase(),
          password: this.password
        })
        await Auth.initSession(this.companyId, this.userId)
        this.$router.push(this.redirectLink)
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!expectedLoginErrorCodes.some(code => code === e.code)) {
          Raven.captureException(e)
        }
        this.errorMessage = getErrorMessage(e)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.hero
  background-repeat: no-repeat
  background-size: cover
  background-position: center

.hero-body
  background: rgba(0,0,0,0.7)

.logo
  width: 10rem

.logo-position
  position: absolute
  left: 20px
  top: 20px
</style>
