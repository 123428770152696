import imgUrl from '@lmpm/helpers/browser/img-url'
import Vue from 'vue'
import * as types from '../mutation-types'
import ApiGateway from '@/services/api-gateway'

// Initial state
const state = {}

// Getters
const getters = {
  companyList (state) {
    return Object.keys(state).map((companyId) => {
      return state[companyId]
    })
  },
  activeCompanyId (state, getters, rootState) {
    if (!rootState.userPool.user) return
    if (rootState.userPool.user.attributes['custom:active_company_id']) return rootState.userPool.user.attributes['custom:active_company_id']
    if (getters.companyList[0]) return getters.companyList[0].id
  },
  activeCompany (state, getters) {
    return state[getters.activeCompanyId]
  },
  activeCompanyLogoUrl (state, getters) {
    const activeCompany = getters.activeCompany
    if (!activeCompany || !activeCompany.logo) return
    const {
      company_id,
      path,
      file_name
    } = activeCompany.logo
    return imgUrl({
      companyId: company_id,
      path,
      fileName: file_name,
      queryParams: { h: 56 }
    })
  }
}

// Actions
const actions = {
  async getAllCompanies ({
    rootGetters,
    dispatch
  }) {
    await Promise.all(rootGetters.linkedOwnerList.map(({
      company_id,
      id
    }) => {
      return dispatch('getCompany', {
        companyId: company_id,
        userId: id
      })
    }))
  },
  async getCompany ({ commit }, {
    companyId,
    userId
  }) {
    const response = await ApiGateway.invokeApi({
      method: 'GET',
      pathTemplate: '/owners/{userId}/companies/{companyId}',
      params: {
        companyId,
        userId
      }
    })
    const company = response.data
    commit(types.RECEIVE_COMPANY, { company })
  },
  async changeActiveCompany ({ dispatch }, { companyId }) {
    await dispatch('getCurrentUser') // Try to refresh the session token.
    await dispatch('updateAttributes', { 'custom:active_company_id': companyId })
    await dispatch('getUserAttributes')
  }
}

// Mutations
const mutations = {
  [types.RECEIVE_COMPANY] (state, { company }) {
    Vue.set(state, company.id, company)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
