export const expectedLoginErrorCodes = [
  'NotAuthorizedException', // Incorrect password / token expired
  'UserNotFoundException' // Incorect username
]

export const expectedLoginForgotErrorCodes = [
  // User doesn't exist
  'UserNotFoundException'
]

export const expectedSecureRouteErrorCodes = [
  // Expired session
  'NotAuthorizedException'
]

export const loginBgImages = [
  '2923-Heritage-Peaks-Trail-Bath-1A.jpg',
  '2923-Heritage-Peaks-Trail-Living-B.jpg',
  '2923-Heritage-Peaks-Trail-View.jpg',
  '2927-Heritage-Peaks-Trail-54-Print.jpg',
  '2927-View-1.jpg',
  '2939-Exterior-DUSK-cropped.jpg',
  '2939-Living-new.jpg',
  '2951-Exterior.jpg',
  '2951-Living-Area-A.jpg',
  '2951-Media-Room.jpg',
  '2972-Bath-1.jpg',
  '2972-Daylight-Exterior.jpg',
  '2972-Hot-Tub.jpg',
  '2972-Outdoor-Fireplace.jpg',
  '2980-Bath-1.jpg',
  '2980-Pool-A.jpg',
  '2980-Wine-Room.jpg'
]
