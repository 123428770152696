import AWS from 'aws-sdk/global'
import Buefy from 'buefy'
import filterObjDepth from 'filter-obj-depth'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'
import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import { sync } from 'vuex-router-sync'
import App from './App.vue'
import router from './router'
import store from './store'

AWS.config.correctClockSkew = true
Vue.config.productionTip = false
const isLiverez = window.location.href?.includes('liverez')
Vue.prototype.$is_liverez = isLiverez
// set appropriate title and favicon
if (isLiverez) {
  document.title = 'Liverez - Owners'
  document.querySelector("link[rel*='icon']").href = process.env.BASE_URL + 'liverez-favicon.ico'
} else {
  document.title = 'LMPM - Owners'
  document.querySelector("link[rel*='icon']").href = process.env.BASE_URL + 'favicon.ico'
}
const ravenConfig = {
  release: `owners-browser-${process.env.VUE_APP_RELEASE}`,
  environment: process.env.NODE_ENV,
  dataCallback (data) {
    if (data.breadcrumbs) {
      data.breadcrumbs.values = data.breadcrumbs.values.map(value => filterObjDepth(value, 15))
    }
    if (data.extra.propsData) delete data.extra.propsData
    return data
  }
}
Raven.config(process.env.VUE_APP_SENTRY_DSN, ravenConfig)
  .addPlugin(RavenVue, Vue)
  .install()

Vue.use(Buefy)
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GA_TRACKING_ID,
  router,
  debug: { sendHitTask: process.env.NODE_ENV === 'production' }
})

sync(store, router)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
