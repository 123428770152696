import Raven from 'raven-js'
import ApiGateway from '@/services/api-gateway'
import Config from '@/services/config'
import store from '@/store'
import { expectedSecureRouteErrorCodes } from '@/store/constants'

export default class Auth {
  /**
   * Login with Cognito User Pool.
   * @param {Object} params - Method params.
   * @param {String} params.email - Email address.
   * @param {String} params.password - Password.
   */
  static async userPoolLogin ({
    email,
    password
  }) {
    Raven.captureBreadcrumb({
      message: 'Logging in with email/password',
      category: 'action',
      data: { email }
    })
    Raven.setUserContext({ email })
    await store.dispatch('authenticateUser', {
      username: email, // Email address is aliased to `username` in Cognito
      password
    })
  }

  /**
   * Authenticate with Cognito Identity Pool using passed login tokens.
   * Initialize Cognito Sync datasets and populate them with data.
   * Initialize API Gateway client.
   */
  static async initSession (companyId, lmpmUserId) {
    if (!Auth.isLoggedIn()) {
      Raven.captureBreadcrumb({
        message: 'User isn\'t logged in - attempting to reuse existing session',
        category: 'info'
      })
      await store.dispatch('getCurrentUser')
    }
    if (Auth.userAttributesLoaded()) return
    try {
      Raven.captureBreadcrumb({
        message: 'User attributes haven\'t been loaded yet - initializing new session',
        category: 'info'
      })
      await store.dispatch('getUserAttributes')
      Raven.setUserContext({
        id: Config.getCurrentUserCognitoUsername(),
        firstName: store.state.userPool.user.attributes.given_name,
        lastName: store.state.userPool.user.attributes.family_name,
        email: store.state.userPool.user.attributes.email
      })
      await ApiGateway.init({
        invokeUrl: Config.ApiGwUrl,
        headers: { Authorization: store.state.userPool.user.tokens.IdToken }
      })
      if (companyId && lmpmUserId) {
        await store.dispatch('linkOwner', {
          companyId,
          lmpmUserId
        })
      }
      await store.dispatch('getLinkedOwners')
      await store.dispatch('getAllCompanies')
    } catch (e) {
      if (e.code === 'NotAuthorizedException' || e.code === 'ResourceNotFoundException') {
        await Auth.logout()
      }
      throw e
    }
  }

  /**
   * Is the user logged in?
   */
  static isLoggedIn () {
    return Boolean(store.state.userPool.user && store.state.userPool.user.tokens)
  }

  /**
   * Have the user's attributes been loaded from Cognito yet?
   */
  static userAttributesLoaded () {
    return Boolean(store.state.userPool.user && store.state.userPool.user.attributes && store.state.userPool.user.attributes.email)
  }

  /**
   * Log out all logins and empty local storage.
   */
  static async logout () {
    Raven.captureBreadcrumb({
      message: 'Logging out',
      category: 'action'
    })
    if (Auth.isLoggedIn()) {
      store.commit('SET_LOGGING_OUT', true)
      await store.dispatch('signOut')
    }
    Raven.setUserContext()
  }

  // vue-router navigation guards //

  /**
   * Make sure the user is logged in before navigating to a route.
   */
  static async secureRoute (routeTo, routeFrom, next) {
    Raven.captureBreadcrumb({
      message: 'Securing route',
      category: 'action',
      data: {
        routeTo: Object.assign({}, routeTo, { matched: '** redacted due to Sentry request size limit **' }),
        routeFrom: Object.assign({}, routeFrom, { matched: '** redacted due to Sentry request size limit **' })
      }
    })
    // Attempt to authenticate with cached credentials and redirect to login page if expired.
    try {
      await Auth.initSession()
    } catch (e) {
      let error
      if (!(e instanceof Error) && e.message) {
        error = new Error(e.message)
        error.code = e.code
      } else {
        error = e
      }
      if (!expectedSecureRouteErrorCodes.some(code => code === error.code)) {
        Raven.captureException(error)
      }
      return next({
        path: '/login',
        query: {
          redirect: routeTo.fullPath,
          expired: true
        }
      })
    }
    next()
  }

  /**
   * Clear cached auth before navigating to the target route.
   * Useful for login/signup pages in case the user wants to use different credentials.
   */
  static async clearCachedAuth (to, from, next) {
    if (Auth.isLoggedIn()) await Auth.logout()
    next()
  }

  /**
   * Log out the user by navigating to a route.
   */
  static async routerLogout (to, from, next) {
    await Auth.logout()
    Auth.logoutBrowserRedirect()
  }

  /**
   * Set `window.location` in a wrapper method so it can be stubbed in tests.
   */
  static logoutBrowserRedirect () {
    window.location = '/login?signedOut=true'
  }
}
