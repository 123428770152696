import * as types from '../mutation-types'
import ApiGateway from '@/services/api-gateway'

// Initial state
const state = { active: {} }

// Getters
const getters = {
  billsForProperty: state => ({ propertyId }) => state[propertyId],
  activeBill: state => state.active
}

// Actions
const actions = {
  async getPropertyBills ({
    commit,
    rootGetters
  }, {
    propertyId,
    start,
    end
  }) {
    const companyId = rootGetters.activeCompanyId
    const userId = rootGetters.activeLinkedOwnerId
    const queryParams = {
      start,
      end
    }
    const response = await ApiGateway.invokeApi({
      method: 'GET',
      pathTemplate: '/companies/{companyId}/owners/{userId}/properties/{propertyId}/bills',
      params: {
        companyId,
        userId,
        propertyId
      },
      additionalParams: { queryParams }
    })
    const bills = response.data
    commit(types.RECEIVE_PROPERTY_BILLS, {
      propertyId,
      bills
    })
  },
  async getActiveBill ({
    commit,
    rootGetters
  }, {
    propertyId,
    billId
  }) {
    const companyId = rootGetters.activeCompanyId
    const userId = rootGetters.activeLinkedOwnerId
    const response = await ApiGateway.invokeApi({
      method: 'GET',
      pathTemplate: '/companies/{companyId}/owners/{userId}/properties/{propertyId}/bills/{billId}',
      params: {
        companyId,
        userId,
        propertyId,
        billId
      }
    })
    const bill = response.data
    commit(types.RECEIVE_ACTIVE_BILL, { bill })
  }
}

// Mutations
const mutations = {
  [types.RECEIVE_PROPERTY_BILLS] (state, {
    propertyId,
    bills
  }) {
    state[propertyId] = bills
  },
  [types.RECEIVE_ACTIVE_BILL] (state, { bill }) {
    state.active = bill
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
