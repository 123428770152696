<template>
  <div class="no-results">
    <div class="no-results-body">
      <h3 class="subtitle">
        <slot />
      </h3>
    </div>
  </div>
</template>

<script>
export default { name: 'NoResults' }
</script>

<style lang="sass" scoped>
.no-results
  display: flex
  align-items: center
  min-height: calc(65vh - 6rem - 3.25rem)

  .no-results-body
    width: 100%
    text-align: center

  .subtitle
    margin-top: 1rem
</style>
