import * as types from '../mutation-types'
import ApiGateway from '@/services/api-gateway'

// Initial state
const state = {
  all: [],
  hasYearEnd: false
}

// Getters
const getters = {
  allStatements: state => state.all,
  hasYearEndStatement: state => state.hasYearEnd
}

// Actions
const actions = {
  async getAllStatements ({
    commit,
    rootGetters
  }) {
    const response = await ApiGateway.invokeApi({
      method: 'GET',
      pathTemplate: '/companies/{companyId}/owners/{userId}/statements',
      params: {
        companyId: rootGetters.activeCompanyId,
        userId: rootGetters.activeLinkedOwnerId
      }
    })
    const statements = response.data
    commit(types.RECEIVE_STATEMENTS, { statements })
  }
}

// Mutations
const mutations = {
  [types.RECEIVE_STATEMENTS] (state, { statements }) {
    state.all = statements.statements
    state.hasYearEnd = statements.hasYearEnd
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
