<template>
  <div class="book-property is-hidden-mobile">
    <b-tooltip
      class="subnav-tooltip"
      label="Property navigation"
      position="is-bottom"
      type="is-dark"
      always
    />
    <b-tooltip
      class="availability-tooltip"
      label="Book your property"
      position="is-left"
      type="is-dark"
      always
    />
  </div>
</template>

<script>
export default {
  name: 'PropertyBook',

  activated () {
    this.$store.dispatch('availabilityIsHiddenMobile', false)
  },

  deactivated () {
    this.$store.dispatch('availabilityIsHiddenMobile', true)
  }
}
</script>

<style lang="sass" scoped>
.book-property
  position: relative
  margin-top: -25px

.subnav-tooltip
  position: absolute
  left: 12rem
  top: 1rem

.availability-tooltip
  position: absolute
  right: 0
  top: 5rem
</style>
