export const RECEIVE_ACTIVE_PROPERTY = 'RECEIVE_ACTIVE_PROPERTY'
export const RECEIVE_PROPERTIES = 'RECEIVE_PROPERTIES'
export const RECEIVE_PROPERTY_ITEMS = 'RECEIVE_PROPERTY_ITEMS'
export const RECEIVE_PROPERTY_BILLS = 'RECEIVE_PROPERTY_BILLS'
export const RECEIVE_ACTIVE_BILL = 'RECEIVE_ACTIVE_BILL'
export const RECEIVE_STATEMENTS = 'RECEIVE_STATEMENTS'
export const RECEIVE_LINKED_OWNERS = 'RECEIVE_LINKED_OWNERS'
export const RECEIVE_COMPANY = 'RECEIVE_COMPANY'
export const CANCEL_PROPERTY_ITEM = 'CANCEL_PROPERTY_ITEM'
export const BOOK_PROPERTY_ITEM = 'BOOK_PROPERTY_ITEM'
export const CHANGE_AVAILABILITY_IS_HIDDEN_MOBILE = 'CHANGE_AVAILABILITY_IS_HIDDEN_MOBILE'
export const SET_LOGGING_OUT = 'SET_LOGGING_OUT'
export const SET_DATE_RANGE = 'SET_DATE_RANGE'
export const RESET_PROPERTY_ITEMS = 'RESET_PROPERTY_ITEMS'
export const UPDATE_FUTURE = 'UPDATE_FUTURE'
