<template>
  <section class="section">
    <div class="container">
      <change-active-company />
      <br><br>

      <loading-spinner :loading="loading" />
      <!--
      <section v-if="!loading && (activeCompanyId && activeLinkedOwnerId)">
        <h3 class="title is-4">Notifications</h3>
        <b-field>
          <b-switch id="ownerBookingNotification" v-model="bookingNotification"> Receive email notification when a booking is made</b-switch>
        </b-field>
      </section>
      <br><br>
      -->

      <change-password />
      <br><br>

      <b-field>
        <router-link
          class="button is-danger"
          tag="button"
          to="/logout"
        >
          <b-icon
            icon="power"
            size="is-small"
          />
          <span>Log Out</span>
        </router-link>
      </b-field>
    </div>
  </section>
</template>

<script>
import Raven from 'raven-js'
import LoadingSpinner from 'vue-spinner/src/MoonLoader'
import { mapGetters } from 'vuex'
import ChangeActiveCompany from '@/components/ChangeActiveCompany'
import ChangePassword from '@/components/ChangePassword'
import ApiGateway from '@/services/api-gateway'
import { getErrorMessage } from '@/services/helpers'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Settings',

  components: {
    LoadingSpinner,
    ChangePassword,
    ChangeActiveCompany
  },

  data () {
    return {
      errorMessage: null,
      bookingNotification: false,
      loading: false,
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: ''
    }
  },

  computed: mapGetters([
    'activeLinkedOwnerId',
    'activeCompanyId'
  ]),

  watch: {
    async bookingNotification () {
      await this.submitNotificationSettings()
    }
  },

  async created () {
    await this.getNotificationSettings()
  },

  methods: {
    async submitNotificationSettings () {
      try {
        const userId = this.activeLinkedOwnerId
        const companyId = this.activeCompanyId
        const notificationSettings = { booking_notification_owner: this.bookingNotification }
        await ApiGateway.invokeApi({
          method: 'POST',
          pathTemplate: '/companies/{companyId}/owners/{userId}/settings',
          params: {
            companyId,
            userId
          },
          body: notificationSettings
        })
      } catch (e) {
        Raven.captureException(e)
        this.$buefy.toast.open({
          message: getErrorMessage(e),
          type: 'is-danger'
        })
      }
    },

    async getNotificationSettings () {
      const companyId = this.activeCompanyId
      const userId = this.activeLinkedOwnerId
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!companyId || !userId) return
      this.loading = true
      try {
        const response = await ApiGateway.invokeApi({
          method: 'GET',
          pathTemplate: '/companies/{companyId}/owners/{userId}/settings',
          params: {
            companyId,
            userId
          }
        })
        this.settings = response.data
        this.setNotifications(this.settings)
      } catch (e) {
        Raven.captureException(e)
        this.$buefy.toast.open({
          message: `Error loading owner settings - ${getErrorMessage(e)}`,
          type: 'is-danger'
        })
      }
      this.loading = false
    },

    setNotifications (settings) {
      for (let i = 0; i < settings.length; i++) {
        switch (settings[i].id) {
          case 'booking_notification_owner':
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            this.bookingNotification = !settings[i].params.email_opt_out
            break
        }
      }
    }
  }
}
</script>
