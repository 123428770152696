<template>
  <section>
    <h3 class="title is-4">
      Management Company
    </h3>
    <b-field
      :type="{ 'is-danger': errorMessage }"
      :message="errorMessage"
    >
      <b-select
        v-model="activeCompanyId"
        :loading="loading"
        :disabled="loading"
      >
        <option
          v-for="company of companyList"
          :key="company.id"
          :value="company.id"
        >
          {{ company.name }}
        </option>
      </b-select>
    </b-field>
  </section>
</template>

<script>
import Raven from 'raven-js'
import { mapGetters } from 'vuex'
import { getErrorMessage } from '@/services/helpers'

export default {
  name: 'ChangeActiveCompany',

  data () {
    return {
      errorMessage: null,
      activeCompanyId: this.$store.getters.activeCompanyId,
      loading: false,
      success: null
    }
  },

  computed: mapGetters(['companyList']),

  watch: {
    async activeCompanyId (companyId) {
      this.errorMessage = null
      this.loading = true
      try {
        await this.$store.dispatch('changeActiveCompany', { companyId })
      } catch (e) {
        Raven.captureException(e)
        this.errorMessage = getErrorMessage(e)
      }
      this.loading = false
    }
  }
}
</script>
