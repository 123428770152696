import * as types from '../mutation-types'
import ApiGateway from '@/services/api-gateway'

// Initial state
const state = {
  all: [],
  active: {}
}

// Getters
const getters = {
  allProperties: state => state.all,
  activeProperty: state => state.active
}

// Actions
const actions = {
  async getAllProperties ({
    commit,
    rootGetters
  }) {
    const response = await ApiGateway.invokeApi({
      method: 'GET',
      pathTemplate: '/companies/{companyId}/owners/{userId}/properties',
      params: {
        companyId: rootGetters.activeCompanyId,
        userId: rootGetters.activeLinkedOwnerId
      }
    })
    const properties = response.data
    commit(types.RECEIVE_PROPERTIES, { properties })
  },
  async getActiveProperty ({
    commit,
    rootGetters
  }, { propertyId }) {
    const companyId = rootGetters.activeCompanyId
    const userId = rootGetters.activeLinkedOwnerId
    const response = await ApiGateway.invokeApi({
      method: 'GET',
      pathTemplate: '/companies/{companyId}/owners/{userId}/properties/{propertyId}',
      params: {
        companyId,
        userId,
        propertyId
      }
    })
    const property = response.data
    commit(types.RECEIVE_ACTIVE_PROPERTY, { property })
  }
}

// Mutations
const mutations = {
  [types.RECEIVE_PROPERTIES] (state, { properties }) {
    state.all = properties
  },
  [types.RECEIVE_ACTIVE_PROPERTY] (state, { property }) {
    state.active = property
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
