import * as types from '../mutation-types'

// Initial state
const state = { availabilityIsHiddenMobile: true }

// Getters
const getters = { availabilityIsHiddenMobile: state => state.availabilityIsHiddenMobile }

// Actions
const actions = {
  availabilityIsHiddenMobile ({ commit }, hidden) {
    commit(types.CHANGE_AVAILABILITY_IS_HIDDEN_MOBILE, hidden)
  }
}

// Mutations
const mutations = {
  [types.CHANGE_AVAILABILITY_IS_HIDDEN_MOBILE] (state, hidden) {
    state.availabilityIsHiddenMobile = hidden
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
