<template>
  <nav
    v-if="showRouteNav"
    class="navbar is-dark has-shadow"
  >
    <div class="container">
      <div class="navbar-brand">
        <router-link
          class="navbar-item"
          to="/properties"
          style="width: "
        >
          <img
            v-if="activeCompanyLogoUrl"
            :src="activeCompanyLogoUrl"
            class="logo logo-sm"
            style="margin-right: 7px;"
          >
          <img
            v-if="!activeCompanyLogoUrl && $is_liverez"
            src="~@/assets/img/liverez-logo-light.svg"
            class="logo logo-sm"
            style="margin-right: 7px;"
          >
          <img
            v-if="!activeCompanyLogoUrl && !$is_liverez"
            src="~@/assets/img/logo-light.svg"
            class="logo logo-sm"
            style="margin-right: 7px;"
          >
          <div>- Owner Portal</div>
        </router-link>
        <div
          :class="{ 'is-active': mobileNavOpen }"
          class="navbar-burger"
          @click="toggleMobileNav"
        >
          <span /><span /><span />
        </div>
      </div>

      <div
        :class="{ 'is-active': mobileNavOpen }"
        class="navbar-menu"
      >
        <div class="navbar-end">
          <router-link
            :class="{ 'is-tab': !mobileNavOpen }"
            to="/properties"
            class="navbar-item"
            active-class="is-active"
            @click.native="closeMobileNav"
          >
            Properties
          </router-link>

          <router-link
            :class="{ 'is-tab': !mobileNavOpen }"
            to="/statements"
            class="navbar-item"
            active-class="is-active"
            @click.native="closeMobileNav"
          >
            Statements
          </router-link>

          <router-link
            :class="{ 'is-tab': !mobileNavOpen }"
            to="/settings"
            class="navbar-item"
            active-class="is-active"
            @click.native="closeMobileNav"
          >
            Settings
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MainNav',

  data () {
    return { mobileNavOpen: false }
  },

  computed: mapGetters([
    'showRouteNav',
    'activeCompanyLogoUrl'
  ]),

  methods: {
    toggleMobileNav () {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      this.mobileNavOpen = !this.mobileNavOpen
    },
    closeMobileNav () {
      this.mobileNavOpen = false
    }
  }
}
</script>
