import isBefore from 'date-fns/is_before'
import isDate from 'date-fns/is_date'
import parse from 'date-fns/parse'
import startOfYesterday from 'date-fns/start_of_yesterday'
import Vue from 'vue'
import * as types from '../mutation-types'
import ApiGateway from '@/services/api-gateway'

const getDefaultPiState = () => {
  return {
    futurePropertyItemPageStart: 0,
    pastPropertyItemPageStart: 0,
    futureItems: [],
    pastItems: [],
    futureDateRange: [],
    pastDateRange: []
  }
}

// Initial state
const state = {
  lastBookedItem: {},
  lastCancelledItem: {},
  propertyItemPageSize: 30
}

// Getters
const getters = {
  itemsForProperty: state => ({
    propertyId,
    future
  }) => {
    if (state[propertyId]) {
      return future ? state[propertyId].futureItems : state[propertyId].pastItems
    } else {
      return []
    }
  },
  endOfPropertyItems: state => ({
    propertyId,
    future
  }) => {
    if (state[propertyId]) {
      return future ? state[propertyId].endOfFutureItems : state[propertyId].endOfPastItems
    } else {
      return false
    }
  },
  lastBookedPropertyItem: state => state.lastBookedItem,
  dateRangeForProperty: state => ({
    propertyId,
    future
  }) => {
    if (state[propertyId]) {
      return future ? state[propertyId].futureDateRange : state[propertyId].pastDateRange
    }
  },
  lastCancelledPropertyItem: state => state.lastCancelledItem,
  propertyItemBookingTypeLabel: state => ({ bookingType }) => {
    switch (bookingType) {
      case 'owner':
        return 'You'
      case 'owner_guest':
        return 'Your Guest'
      case 'renter':
        return 'Guest'
      default:
        return ''
    }
  },
  propertyItemBookingTypeNameLabel: state => ({ bookingType }) => {
    switch (bookingType) {
      case 'owner':
        return 'Owner'
      case 'owner_guest':
        return 'Owner Guest'
      case 'renter':
        return 'Guest'
      default:
        return ''
    }
  },
  propertyItemBookingSourceLabel: state => ({ bookingSource }) => {
    switch (bookingSource) {
      case 'owner-app':
        return 'Owner'
      case 'lmpm-app':
        return 'LMPM'
      case 'api':
        return 'LMPM Website'
      default:
        return bookingSource
    }
  },
  propertyItemLabelCssClass: state => ({ bookingType }) => {
    switch (bookingType) {
      case 'owner':
        return 'is-info-light'
      case 'renter':
        return 'is-success-light'
      case 'owner_guest':
        return 'is-success-light owner-guest-bg'
      default:
        return ''
    }
  },
  propertyItemIsCancellable: state => ({
    bookingType,
    startDate
  }) => {
    if (bookingType !== 'owner' && bookingType !== 'owner_guest') return false
    if (!isDate(startDate)) startDate = parse(startDate)
    const yesterday = startOfYesterday()
    if (isBefore(startDate, yesterday)) return false
    return true
  }
}

// Actions
const actions = {
  async getPropertyItems ({
    commit,
    rootGetters
  }, {
    propertyId,
    start,
    end,
    future
  }) {
    const companyId = rootGetters.activeCompanyId
    const userId = rootGetters.activeLinkedOwnerId
    const pageSize = state.propertyItemPageSize
    let pageStart = 0
    if (state[propertyId]) {
      pageStart = future ? state[propertyId].futurePropertyItemPageStart : state[propertyId].pastPropertyItemPageStart
    }
    const queryParams = {
      start,
      end,
      future,
      pageStart,
      pageSize
    }
    const response = await ApiGateway.invokeApi({
      method: 'GET',
      pathTemplate: '/companies/{companyId}/owners/{userId}/properties/{propertyId}/items',
      params: {
        companyId,
        userId,
        propertyId
      },
      additionalParams: { queryParams }
    })
    const propertyItems = response.data
    commit(types.RECEIVE_PROPERTY_ITEMS, {
      propertyId,
      propertyItems,
      future
    })
  },
  async cancelPropertyItem ({
    commit,
    rootGetters
  }, {
    propertyId,
    tripId,
    id
  }) {
    const companyId = rootGetters.activeCompanyId
    const userId = rootGetters.activeLinkedOwnerId
    await ApiGateway.invokeApi({
      method: 'DELETE',
      pathTemplate: '/companies/{companyId}/owners/{userId}/trips/{tripId}/items/{id}',
      params: {
        companyId,
        userId,
        tripId,
        id
      }
    })
    commit(types.CANCEL_PROPERTY_ITEM, {
      propertyId,
      id
    })
  },
  async bookPropertyItem ({
    commit,
    rootGetters
  }, {
    propertyId,
    propertyItem
  }) {
    const companyId = rootGetters.activeCompanyId
    const userId = rootGetters.activeLinkedOwnerId
    const response = await ApiGateway.invokeApi({
      method: 'POST',
      pathTemplate: '/companies/{companyId}/owners/{userId}/properties/{propertyId}/book',
      params: {
        companyId,
        userId,
        propertyId
      },
      body: propertyItem
    })
    const bookedItem = response.data
    commit(types.BOOK_PROPERTY_ITEM, {
      propertyId,
      bookedItem
    })
  },
  setDateRange ({ commit }, {
    propertyId,
    future,
    dateRange
  }) {
    commit(types.SET_DATE_RANGE, {
      propertyId,
      future,
      dateRange
    })
  },
  resetPropertyItems ({ commit }, {
    propertyId,
    future
  }) {
    commit(types.RESET_PROPERTY_ITEMS, {
      propertyId,
      future
    })
  },
  updateFuture ({ commit }, { future }) {
    commit(types.UPDATE_FUTURE, { future })
  }
}

// Mutations
const mutations = {
  [types.RECEIVE_PROPERTY_ITEMS] (state, {
    propertyId,
    propertyItems,
    future
  }) {
    state.future = future
    let tempPiState
    if (!state[propertyId]) {
      tempPiState = getDefaultPiState()
    } else {
      tempPiState = state[propertyId]
    }
    if (future) {
      tempPiState.futureItems = tempPiState.futureItems.concat(propertyItems)
      tempPiState.futurePropertyItemPageStart += state.propertyItemPageSize
      if (propertyItems.length < state.propertyItemPageSize) {
        tempPiState.endOfFutureItems = true
      }
    } else {
      tempPiState.pastItems = tempPiState.pastItems.concat(propertyItems)
      tempPiState.pastPropertyItemPageStart += state.propertyItemPageSize
      if (propertyItems.length < state.propertyItemPageSize) {
        tempPiState.endOfPastItems = true
      }
    }
    Vue.set(state, propertyId, tempPiState)
  },
  [types.CANCEL_PROPERTY_ITEM] (state, {
    propertyId,
    id
  }) {
    let tempPiState
    if (!state[propertyId]) {
      tempPiState = getDefaultPiState()
    } else {
      tempPiState = state[propertyId]
    }
    let cancelledItem
    tempPiState.futureItems = tempPiState.futureItems.filter((item) => {
      if (item.id !== id) return true
      cancelledItem = item
      return false
    })
    Vue.set(state, propertyId, tempPiState)
    Vue.set(state, 'lastCancelledItem', cancelledItem)
  },
  [types.BOOK_PROPERTY_ITEM] (state, {
    propertyId,
    bookedItem
  }) {
    let tempPiState
    if (!state[propertyId]) {
      tempPiState = getDefaultPiState()
    } else {
      tempPiState = state[propertyId]
    }
    if (state.future) {
      tempPiState.futureItems.push(bookedItem)
    }
    Vue.set(state, propertyId, tempPiState)
    Vue.set(state, 'lastBookedItem', bookedItem)
  },
  [types.SET_DATE_RANGE] (state, {
    propertyId,
    future,
    dateRange
  }) {
    let tempPiState
    if (!state[propertyId]) {
      tempPiState = getDefaultPiState()
    } else {
      tempPiState = state[propertyId]
    }
    if (future) {
      tempPiState.futureDateRange = dateRange
    } else {
      tempPiState.pastDateRange = dateRange
    }
    Vue.set(state, propertyId, tempPiState)
  },
  [types.RESET_PROPERTY_ITEMS] (state, {
    propertyId,
    future
  }) {
    let tempPiState
    if (!state[propertyId]) {
      tempPiState = getDefaultPiState()
    } else {
      tempPiState = state[propertyId]
    }
    if (future) {
      tempPiState.futureItems = []
      tempPiState.futurePropertyItemPageStart = 0
    } else {
      tempPiState.pastItems = []
      tempPiState.pastPropertyItemPageStart = 0
    }
    Vue.set(state, propertyId, tempPiState)
  },
  [types.UPDATE_FUTURE] (state, { future }) {
    state.future = future
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
