<template>
  <div>
    <div class="scroll-indicators is-hidden-tablet">
      <transition name="fade">
        <div
          v-show="showScrollLeft"
          class="indicator indicator-left is-pulled-left"
        >
          <b-icon
            icon="chevron-left"
            size="is-small"
          />
        </div>
      </transition>

      <transition name="fade">
        <div
          v-show="showScrollRight"
          class="indicator indicator-right is-pulled-right"
        >
          <b-icon
            icon="chevron-right"
            size="is-small"
          />
        </div>
      </transition>
    </div>

    <div
      ref="tabs"
      class="tabs"
      @scroll="updateScrollIndicators"
    >
      <ul>
        <router-link
          :to="futureItemsPath"
          tag="li"
          class="future-items"
          active-class="is-active"
        >
          <a>Future Bookings</a>
        </router-link>

        <router-link
          :to="pastItemsPath"
          tag="li"
          class="past-items"
          active-class="is-active"
        >
          <a>Past Bookings</a>
        </router-link>

        <router-link
          :to="bookPath"
          tag="li"
          class="book is-hidden-tablet"
          active-class="is-active"
        >
          <a>Book</a>
        </router-link>

        <router-link
          v-if="showStatisticsTab"
          :to="statisticsPath"
          tag="li"
          class="statistics"
          active-class="is-active"
        >
          <a>Statistics</a>
        </router-link>

        <router-link
          :to="detailsPath"
          tag="li"
          class="details"
          active-class="is-active"
        >
          <a>Details</a>
        </router-link>

        <router-link
          v-if="showChargesTab"
          :to="billsPath"
          tag="li"
          class="bills"
          active-class="is-active"
        >
          <a>Charges</a>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PropertyViewSubNav',

  props: {
    propertyId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      showScrollLeft: false,
      showScrollRight: true,
      scrollMargin: 20
    }
  },

  computed: {
    ...mapGetters(['activeCompany']),
    statisticsPath () { return `/properties/${this.propertyId}/statistics` },
    detailsPath () { return `/properties/${this.propertyId}/details` },
    bookPath () { return `/properties/${this.propertyId}/book` },
    futureItemsPath () { return `/properties/${this.propertyId}/future-bookings` },
    pastItemsPath () { return `/properties/${this.propertyId}/past-bookings` },
    billsPath () { return `/properties/${this.propertyId}/charges` },
    showStatisticsTab () { return Boolean(this.activeCompany.show_statistics_tab) },
    showChargesTab () { return Boolean(this.activeCompany.show_charges_tab) }
  },

  created () {
    this.updateScrollIndicators()
    window.addEventListener('resize', this.updateScrollIndicators)
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.updateScrollIndicators)
  },

  methods: {
    updateScrollIndicators () {
      const target = this.$refs.tabs
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!target) return
      this.showScrollLeft = target.scrollLeft > this.scrollMargin
      if (target.scrollWidth > target.offsetWidth) {
        this.showScrollRight = target.scrollLeft < target.scrollWidth - target.offsetWidth - this.scrollMargin
      } else {
        this.showScrollRight = false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.tabs
  margin-bottom: 1.5em

.scroll-indicators
  position: absolute
  width: 100%
  pointer-events: none
  z-index: 1 // Indicator background isn't displayed in iOS Safari without this

  .indicator
    padding: 0.5em 0
    background: rgba(255,255,255,0.9)
</style>
