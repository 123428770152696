<template>
  <div class="image-gallery columns is-multiline is-mobile">
    <div
      v-for="image of images"
      :key="image.id"
      class="column is-one-third-mobile is-3-tablet is-2-desktop"
    >
      <div class="card">
        <a
          class="card-image"
          @click="view(image)"
        >
          <figure class="image is-16by9">
            <img
              v-if="isImage(image)"
              :src="thumbnail(image)"
            >
            <span
              v-if="isPdf(image)"
              class="pdf-thumbnail"
            >
              <b-icon icon="file-pdf" />
              <small :title="image.file_name">{{ image.file_name }}</small>
            </span>
          </figure>
        </a>
      </div>
    </div>

    <div
      :class="{ 'is-active': showModal }"
      class="modal"
    >
      <div
        class="modal-background"
        @click="closeModal"
      />
      <div class="modal-content">
        <loading-spinner
          :loading="loading"
          size="100px"
        />
        <figure class="image">
          <img :src="fullsize">
        </figure>
      </div>
      <button
        class="modal-close"
        @click="closeModal"
      />
    </div>
  </div>
</template>

<script>
import imgUrl from '@lmpm/helpers/browser/img-url'
import LoadingSpinner from 'vue-spinner/src/MoonLoader'

export default {
  name: 'ImageGallery',

  components: { LoadingSpinner },

  props: {
    images: {
      type: Array,
      default () { return [] }
    },
    width: {
      type: Number,
      default: 1920
    },
    height: {
      type: Number,
      default: 1200
    },
    quality: {
      type: Number,
      default: 75
    },
    thumbWidth: {
      type: Number,
      default: 640
    },
    thumbHeight: {
      type: Number,
      default: 360
    },
    thumbQuality: {
      type: Number,
      default: 50
    },
    viewOriginal: {
      type: Boolean,
      default: false
    } // View the un-resized original image.
  },

  data () {
    return {
      loading: true,
      showModal: false,
      fullsize: '',
      imageTypes: [
        'image/jpeg',
        'image/png',
        'image/gif'
      ]
    }
  },

  methods: {
    isImage (image) { return this.imageTypes.some(type => image.type === type) },
    isPdf (image) { return image.type === 'application/pdf' },

    thumbnail (image) {
      const queryParams = {}
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (this.thumbWidth) queryParams.w = this.thumbWidth
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (this.thumbHeight) queryParams.h = this.thumbHeight
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (this.thumbQuality) queryParams.q = this.thumbQuality
      const params = {
        companyId: image.company_id,
        path: image.path,
        fileName: image.file_name,
        queryParams
      }
      return imgUrl(params)
    },

    view (image) {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (this.isImage(image)) return this.viewImage(image)
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (this.isPdf(image)) return this.viewPdf(image)
    },

    viewImage (image) {
      const queryParams = {}
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!this.viewOriginal) {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (this.width) queryParams.w = this.width
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (this.height) queryParams.h = this.height
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (this.quality) queryParams.q = this.quality
      }
      const params = {
        companyId: image.company_id,
        path: image.path,
        fileName: image.file_name,
        queryParams
      }
      this.fullsize = imgUrl(params)
      this.showModal = true
    },

    viewPdf (image) {
      const pdfLink = imgUrl({
        companyId: image.company_id,
        path: image.path,
        fileName: image.file_name
      })
      window.open(pdfLink, '_blank')
    },

    closeModal () {
      this.showModal = false
      this.fullsize = ''
    }
  }
}
</script>

<style lang="sass" scoped>
.image
  .pdf-thumbnail
    position: absolute
    top: 0
    right: 0
    left: 0
    bottom: 0
    padding: 5px
    text-align: center
    background: rgba(0,0,0,0.02)
    display: flex
    flex-direction: column
    justify-content: center

    > *
      display: flex

    .icon
      font-size: 2em
      margin: 0 auto 0.5rem

    small
      display: block
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      line-height: 1rem

.modal-content
  width: 80%

  .v-spinner
    position: fixed
    top: calc(50% - 50px)
    left: calc(50% - 50px)
</style>
