<template>
  <div class="field is-grouped">
    <p class="control">
      <span class="select">
        <select
          :value="value.month"
          :disabled="disabled"
          @change="monthChanged($event.target.value)"
        >
          <option value="0">January</option>
          <option value="1">February</option>
          <option value="2">March</option>
          <option value="3">April</option>
          <option value="4">May</option>
          <option value="5">June</option>
          <option value="6">July</option>
          <option value="7">August</option>
          <option value="8">September</option>
          <option value="9">October</option>
          <option value="10">November</option>
          <option value="11">December</option>
        </select>
      </span>
    </p>

    <p class="control">
      <input
        :value="value.year"
        :min="minYear"
        :max="maxYear"
        :disabled="disabled"
        class="input"
        type="number"
        @input="yearChanged($event.target.value)"
      >
    </p>
  </div>
</template>

<script>
export default {
  name: 'MonthYearPicker',

  props: {
    maxYearsForward: {
      type: Number,
      default: 25
    },
    maxYearsBack: {
      type: Number,
      default: 25
    },
    value: {
      type: Object,
      default () {
        return {
          month: new Date().getMonth(),
          year: new Date().getFullYear()
        }
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    minYear () { return new Date().getFullYear() - this.maxYearsBack },
    maxYear () { return new Date().getFullYear() + this.maxYearsForward }
  },

  methods: {
    monthChanged (value) {
      const month = Number(value)
      const year = this.value.year
      this.$emit('input', {
        month,
        year 
      })
    },
    yearChanged (value) {
      if (value.length < 4) return
      if (value.length > 4) value = value.slice(0, 4)
      const month = this.value.month
      const year = Number(value)
      this.$emit('input', {
        month,
        year 
      })
    }
  }
}
</script>
