<template>
  <span class="stats">
    <b-tooltip
      v-if="bedrooms != null"
      position="is-bottom"
      type="is-dark"
      label="Bedrooms"
      class="stat bedrooms"
    >
      <b-icon
        icon="hotel"
        size="is-small"
      />
      <small class="value">{{ bedrooms }}</small>
    </b-tooltip>

    <b-tooltip
      v-if="bathrooms != null"
      position="is-bottom"
      type="is-dark"
      label="Bathrooms"
      class="stat bathrooms"
    >
      <b-icon
        icon="shower"
        size="is-small"
      />
      <small class="value">{{ bathrooms }}</small>
    </b-tooltip>

    <b-tooltip
      v-if="occupancy"
      position="is-bottom"
      type="is-dark"
      label="Occupancy"
      class="stat occupancy"
    >
      <b-icon
        icon="account-group"
        size="is-small"
      />
      <small class="value">{{ occupancy }}</small>
    </b-tooltip>

    <b-tooltip
      v-if="sqFeet"
      position="is-bottom"
      type="is-dark"
      label="Square Feet"
      class="stat sq-feet"
    >
      <b-icon
        icon="ruler-square"
        size="is-small"
      />
      <small class="value">{{ sqFeet }} ft<sup>2</sup></small>
    </b-tooltip>
  </span>
</template>

<script>
export default {
  name: 'PropertyStats',

  props: {
    bathrooms: {
      type: Number,
      default: 0 
    },
    bedrooms: {
      type: Number,
      default: 0 
    },
    maxOccupancy: {
      type: Number,
      default: 0 
    },
    occupancy: {
      type: Number,
      default: 0 
    },
    sqFeet: {
      type: Number,
      default: 0 
    },
    starRating: {
      type: Number,
      default: 0 
    }
  }
}
</script>

<style lang="sass" scoped>
.stats
  .stat
    white-space: nowrap
    margin-right: 1.2rem
    margin-top: 5px

    &:last-child
      margin-right: 0

    .icon,
    .value
      vertical-align: middle

    .value
      margin-left: 3px
</style>
